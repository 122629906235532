
import {defineComponent, ref,watch, onMounted, createVNode} from "vue";
import {
  delService,
  getServiceConfigSchema,
  getServiceList,
  setServiceEnable
} from "@/api/api_x2server";
import {Modal, message} from 'ant-design-vue';
import {useStore} from "vuex";
import {useRouter,useRoute} from 'vue-router'
import {
  ExclamationCircleOutlined
} from '@ant-design/icons-vue';
import monitor from './modules/monitor.vue'

export default defineComponent({
  components: {monitor},
  setup() {
    const imageUrl = ref<string>(sessionStorage.getItem("Host") + "/File/GetImageByServiceType?type=");
    const serviceId = ref<number>();
    const pageType = ref<number>(1);
    const total = ref<number>(0);
    const current = ref<number>(1);
    const pageSize = ref<number>(12);
    const serviceList = ref<any>();
    const store = useStore();
    const router = useRouter()
    const route = useRoute()
    onMounted(() => {
      getList()
    })
    watch(route,() => {
      pageType.value = 1
    })
    const getList = async () => {
      const res: any = await getServiceList({
        Count: pageSize.value,
        Pos: (current.value - 1) * pageSize.value,
      })
      total.value = res.TotalCount
      res.Items.forEach((key: any) => {
        if (key.State === 1) {
          key.enable = true
        } else {
          key.enable = false
        }
      })
      serviceList.value = res.Items
    }
    const onShowSizeChange = () => {
      getList()
    };
    const deleteService = (id: number) => {
      Modal.confirm({
        title: () => '是否删除该服务?',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => '是',
        okType: 'danger',
        cancelText: () => '否',
        async onOk() {
          const res: any = await delService({id})
          if (res.Result.toUpperCase() == "OK") {
            message.success("删除成功！")
            getList();
          }
        },
      });
    };
    const toEditService = async (item: any) => {
      let res: any = await getServiceConfigSchema({id: item.ID})
      let basicItems = [{
        IsReq: true,
        Key: "Name",
        T: "String",
        Title: "服务名",
        V: item.Name
      }, {
        IsReq: false,
        Key: "Des",
        T: "String",
        Title: "服务描述",
        V: item.Des
      }]
      if (res.Schema.Items) {
        res.Schema.Items = basicItems.concat(res.Schema?.Items)
      } else {
        res.Schema.Items = basicItems
      }

      store.commit("setConfigParams", {
        id: item.ID,
        title: item.Name,
        icon: imageUrl.value + item.Type,
        introduce: "修改服务",
        configSchema: res
      });
      router.push({
        name: "configurationView", params: {
          type: "updateService",
          configType: item.Name
        }
      })
    };
    const setEnable = async (service: any) => {
      const res: any = await setServiceEnable({
        Enable: service.State != '1',
        id: service.ID,
      })
      if (res.Result.toUpperCase() == "OK") {
        if (service.State == '1') {
          message.success("停止成功！")
        } else {
          message.success("开启成功！")
        }
        getList();
      }
    }
    const toMonitorView = (id: number) => {
      serviceId.value = id
      pageType.value = 2;
    }
    const backPage = () => {
      pageType.value = 1;
    }
    return {
      pageType,
      imageUrl,
      total,
      current,
      pageSize,
      serviceList,
      serviceId,
      getList,
      onShowSizeChange,
      deleteService,
      toEditService,
      setEnable,
      toMonitorView,
      backPage,
    }
  }
})
