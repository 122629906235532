
import {defineComponent, ref, onMounted, watch, createVNode, nextTick} from "vue";
import {TreeDataItem} from 'ant-design-vue/es/tree/Tree';
import {
  delDevMonitorList,
  getDevList,
  getDevLogicTagList,
  getDevMonitorList,
  getDevOrgTree,
  getDevTagList,
  setDevMonitorList
} from "@/api/api_x2server";
import type {TreeProps} from 'ant-design-vue';
import {
  DesktopOutlined,
  AppstoreOutlined,
  RollbackOutlined,
  TagsFilled,
} from '@ant-design/icons-vue';
import {message} from 'ant-design-vue';

export default defineComponent({
  components: {
    TagsFilled,
    RollbackOutlined
  },
  props: ["serviceId"],
  setup(props: { serviceId: number }, context) {
    const sKeys = ref<(string | number)[]>([''])
    let searchValue = ref<string>('')
    const treeData = ref<TreeDataItem[]>([{
      title: "所有",
      key: '',
    }]);
    const spinning = ref<boolean>(false);
    const tip = ref<string>("");
    const checkAll = ref<boolean>(false);
    const indeterminate = ref<boolean>(false);
    const checkedList = ref<string[]>([])
    const tagList = ref<any>([]);
    const filterTagList = ref<any>([]);
    let plainOptions: string[] = [];
    let selectedDevId: number;
    let selectedDevName = ref<string>("");

    const moData = ref<TreeDataItem[]>([]);
    const checkedKeys = ref<string[]>([]);
    const devIcon = createVNode(DesktopOutlined, {style: 'color:#FF892A'})
    const myLeafIcon = createVNode(AppstoreOutlined)
    const r_devIcon = createVNode(DesktopOutlined, {style: 'color: #00a3ff'})
    const r_tagIcon = createVNode(TagsFilled, {style: 'color: #00a3ff'})
    const r_logicTagIcon = createVNode(TagsFilled, {style: 'color: #FF892A'})


    const listWrap = ref();
    let showItemNum = ref<number>(0);//视窗内应该显示的 DOM 数量
    const scrollTopWrapper = ref<number>(0);
    const start = ref(0); //滚动过程中的开始索引
    const end = ref(0); //滚动过程中的结束索引
    let rightList = ref();//视窗内应该显示的 DOM 数量

    onMounted(() => {
      getGroup()
      getMonitorList()

      //运算出应该显示的 DOM 数量
      showItemNum.value = Math.ceil((listWrap.value.clientHeight - 30) / 32);
      end.value = showItemNum.value
    })
    watch(() => [checkedList.value, searchValue.value], ([newCheck, newSearchValue], [oldCheck, oldSearchValue]) => {
      if (newCheck != oldCheck) {
        if (plainOptions.length != 0) {
          indeterminate.value = !!checkedList.value.length && checkedList.value.length < plainOptions.length;
          checkAll.value = checkedList.value.length === plainOptions.length;
        }
      }
      if (newSearchValue != oldSearchValue) {
        filterTagList.value = tagList.value.filter((e: any) => e.name.toLowerCase().indexOf(searchValue.value.toLowerCase()) != -1)
        checkAll.value = false
        checkedList.value = []
        plainOptions = filterTagList.value.map((e: any) => e.name)
      }
    });

    const onLoadData = async (treeNode: any) => {
      let parent: string;
      let children: any;
      if (treeNode.dataRef.key === '') {
        let res: any = await getDevList()
        children = res.Items.map((e: any) => ({
          title: e.Name,
          key: e.ID,
          isLeaf: true,
          switcherIcon: devIcon,
        }));
      } else {
        if (treeNode.dataRef.parent) {
          parent = treeNode.dataRef.parent + "/" + treeNode.dataRef.title
        } else {
          parent = treeNode.dataRef.title
        }
        children = await getGroup(parent);
        if (children.length == 0) {
          treeNode.dataRef.switcherIcon = myLeafIcon;
        }
      }
      treeNode.dataRef.children = children
      treeData.value = [...treeData.value];
    }
    const onSelect: TreeProps['onSelect'] = async (selectedKeys: (string | number)[], info: any) => {
      if (selectedKeys.length == 0) {
        return
      }
      sKeys.value = selectedKeys
      const dataRef = info.node.dataRef;
      indeterminate.value = false;
      searchValue.value = ""
      checkAll.value = false
      checkedList.value = []
      tagList.value = []
      filterTagList.value = []
      plainOptions = []
      selectedDevName.value = ""
      if (dataRef.switcherIcon == null || dataRef.switcherIcon != devIcon) {
        return
      }
      selectedDevId = dataRef.key;
      selectedDevName.value = dataRef.title;
      let res: any = await getDevTagList({devId: selectedDevId})
      res.Items.forEach((k: any) => {
        tagList.value.push({
          id: k.ID,
          name: k.Name,
          type: "tag"
        })
        plainOptions.push(k.Name)
      })
      let res2: any = await getDevLogicTagList({devId: selectedDevId})
      res2.Items.forEach((k: any) => {
        tagList.value.push({
          id: k.ID,
          name: k.Name,
          type: "logicTag"
        })
        plainOptions.push(k.Name)
      })

      filterTagList.value = tagList.value

      listWrap.value.scrollTop = 0
    };
    const getGroup = async (parent?: any) => {
      const res: any = await getDevOrgTree({parent});
      let trees: any = [];
      res.forEach((e: any) => {
        let tree: any = {
          title: e.Name,
          key: e.ID,
          parent: parent,
          children: []
        }
        if (parent) {
          trees.push(tree)
        } else {
          treeData.value.push(tree)
        }
      })
      if (parent) {
        let res: any = await getDevList({parent})
        res.Items.forEach((e: any) => {
          let tree: any = {
            title: e.Name,
            key: e.ID,
            switcherIcon: devIcon,
          }
          trees.push(tree)
        });
      }
      return trees;
    }
    const getMonitorList = async () => {
      let res: any = await getDevMonitorList({serviceId: props.serviceId})
      moData.value = []
      res.forEach((key: any) => {
        moData.value.push({
          title: key.DevName,
          key: 'dev_' + key.DevID,
          icon: r_devIcon,
          children: key.DevTags.map((e: any) => {
            let tagType = e.TagType === 1 ? r_logicTagIcon : r_tagIcon
            return {
              title: e.TagName,
              key: e.TagID,
              icon: tagType,
            }
          }),
        })
      })
    }

    const backPage = () => {
      context.emit("backPage")
    }
    const onCheckAllChange = (e: any) => {
      indeterminate.value = false;
      checkedList.value = e.target.checked ? plainOptions : []
    };
    const addTag = async () => {
      spinning.value = true
      tip.value = "正在添加测点，请稍后..."
      if (checkedList.value.length == 0) {
        message.error("请先选中一条标签信息！")
        return
      }
      let devTags = filterTagList.value
        .filter((e: any) => checkedList.value.indexOf(e.name) != -1)
      let data: any = {
        IsAllSelected: checkAll.value,
        ServiceId: props.serviceId,
        List: {}
      }
      data.List[selectedDevId] = devTags.map((e: any) => e.id)
      let res: any = await setDevMonitorList(data)
      if (res.Result.toUpperCase() == "OK") {
        message.success("添加成功！")
        let find: any = moData.value.find((e: any) => e.key == 'dev_' + selectedDevId);
        let children = devTags.map((e: any) => {
          return {
            title: e.name,
            key: e.id,
            icon: e.type == 'logicTag' ? r_logicTagIcon : r_tagIcon,
          }
        })
        if (find) {
          children.forEach((key: any) => {
            if (find.children.find((e: any) => e.key == key.key) == null) {
              find.children.push(key)
            }
          })
        } else {
          moData.value.push({
            title: selectedDevName.value,
            key: 'dev_' + selectedDevId,
            icon: r_devIcon,
            children
          })
        }
      }

      spinning.value = false
    }
    const removeTag = async () => {
      spinning.value = true
      tip.value = "正在移除测点，请稍后..."
      if (checkedKeys.value.length == 0) {
        message.error("请先选中一条标签信息！")
        return
      }
      let devTags = checkedKeys.value
        .filter((e: any) => typeof (e) == "number")
      let data: any = {
        serviceId: props.serviceId,
        list:{}
      }
      moData.value.forEach((key: any) => {
        let childrens = key.children.map((e: any) => e.key);
        let thisDevTags = devTags.filter((e: any) => childrens.indexOf(e) != -1)
        if (thisDevTags.length != 0) {
          let devId = key.key.replace("dev_", "")
          data.list[devId] = thisDevTags
        }
      })
      let res: any = await delDevMonitorList(data)
      if (res.Result.toUpperCase() == "OK") {
        message.success("移除成功！")
        let devIds = checkedKeys.value.filter((e: any) => typeof (e) == "string")
        let tagIds = checkedKeys.value.filter((e: any) => typeof (e) == "number")
        moData.value = moData.value.filter((e: any) => devIds.indexOf(e.key) == -1)
        moData.value.forEach((key: any) => {
          key.children = key.children.filter((e: any) => tagIds.indexOf(e.key) == -1)
        })
        checkedKeys.value = []
      }

      spinning.value = false
    }

    const checkChange = (e: any) => {
      let oldList = JSON.parse(JSON.stringify(checkedList.value))
      if (e.target.checked === false) {
        oldList = oldList.filter((ol: any) => ol !== e.target.value)
      } else {
        oldList.push(e.target.value)
      }
      nextTick(() => {
        checkedList.value = oldList
      })
    }

    const scrollListener = () => {
      //获取滚动高度
      scrollTopWrapper.value = listWrap.value.scrollTop;
      //开始的数组索引
      start.value = Math.floor((scrollTopWrapper.value + 20) / 32);
      //结束索引
      end.value = start.value + showItemNum.value;
    }

    return {
      spinning,
      tip,
      treeData,
      sKeys,
      searchValue,
      indeterminate,
      checkAll,
      checkedList,
      tagList,
      filterTagList,
      moData,
      checkedKeys,
      selectedDevName,
      listWrap,
      scrollTopWrapper,
      start,
      end,
      rightList,
      backPage,
      onLoadData,
      onSelect,
      onCheckAllChange,
      addTag,
      removeTag,
      scrollListener,
      checkChange,
    }
  }
})
