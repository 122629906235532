import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b77c592"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item" }
const _hoisted_2 = { class: "imgBox" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { style: {"padding":"0 0 5px 15px","font-size":"12px"} }
const _hoisted_7 = ["title"]
const _hoisted_8 = ["title"]
const _hoisted_9 = ["title"]
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 2 }
const _hoisted_13 = {
  key: 1,
  style: {"text-align":"center","margin-top":"5px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_monitor = _resolveComponent("monitor")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.pageType == 1)
      ? (_openBlock(), _createBlock(_component_a_row, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serviceList, (list) => {
                      return (_openBlock(), _createBlock(_component_a_col, {
                        span: 4,
                        key: list.ID,
                        class: "deviceBox"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_1, [
                            (list.Type != 'WebAPIServer')
                              ? (_openBlock(), _createBlock(_component_a_switch, {
                                  key: 0,
                                  style: {"position":"absolute","top":"15px","right":"15px"},
                                  "checked-children": "启用",
                                  "un-checked-children": "禁用",
                                  checked: list.enable,
                                  "onUpdate:checked": ($event: any) => ((list.enable) = $event),
                                  onChange: ($event: any) => (_ctx.setEnable(list))
                                }, null, 8, ["checked", "onUpdate:checked", "onChange"]))
                              : _createCommentVNode("", true),
                            _createElementVNode("div", _hoisted_2, [
                              (list.Type == 'WebAPIServer')
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 0,
                                    src: _ctx.imageUrl+list.Type,
                                    width: "80"
                                  }, null, 8, _hoisted_3))
                                : (_openBlock(), _createElementBlock("a", {
                                    key: 1,
                                    onClick: ($event: any) => (_ctx.toEditService(list))
                                  }, [
                                    _createElementVNode("img", {
                                      src: _ctx.imageUrl+list.Type,
                                      width: "80"
                                    }, null, 8, _hoisted_5)
                                  ], 8, _hoisted_4))
                            ]),
                            _createElementVNode("div", _hoisted_6, [
                              _createElementVNode("div", null, "ID：" + _toDisplayString(list.ID), 1),
                              _createElementVNode("div", {
                                class: "autohide",
                                title: list.Name
                              }, " 名称：" + _toDisplayString(list.Name), 9, _hoisted_7),
                              _createElementVNode("div", {
                                class: "autohide",
                                title: list.Type
                              }, "服务类型：" + _toDisplayString(list.Type), 9, _hoisted_8),
                              _createElementVNode("div", {
                                class: "autohide",
                                title: list.Type
                              }, [
                                _createTextVNode(" 服务状态： "),
                                (list.State === 1)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, "启用"))
                                  : _createCommentVNode("", true),
                                (list.State === 0)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, "禁用"))
                                  : _createCommentVNode("", true),
                                (list.State === 2)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, "异常"))
                                  : _createCommentVNode("", true)
                              ], 8, _hoisted_9)
                            ]),
                            (list.Type != 'WebAPIServer')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                  (list.SupportDevMonitor)
                                    ? (_openBlock(), _createBlock(_component_a_button, {
                                        key: 0,
                                        type: "primary",
                                        size: "small",
                                        onClick: ($event: any) => (_ctx.toMonitorView(list.ID)),
                                        style: {"margin-right":"25px"}
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" 订阅 ")
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"]))
                                    : _createCommentVNode("", true),
                                  _createVNode(_component_a_button, {
                                    type: "primary",
                                    size: "small",
                                    onClick: ($event: any) => (_ctx.deleteService(list.ID))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode("删除 ")
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, {
              span: 24,
              style: {"margin-top":"10px"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_pagination, {
                  style: {"position":"absolute","right":"0px"},
                  current: _ctx.current,
                  "onUpdate:current": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.current) = $event)),
                  pageSize: _ctx.pageSize,
                  total: _ctx.total,
                  "show-less-items": "",
                  onChange: _ctx.onShowSizeChange
                }, null, 8, ["current", "pageSize", "total", "onChange"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.pageType == 2)
      ? (_openBlock(), _createBlock(_component_monitor, {
          key: 1,
          serviceId: _ctx.serviceId,
          onBackPage: _ctx.backPage
        }, null, 8, ["serviceId", "onBackPage"]))
      : _createCommentVNode("", true)
  ]))
}